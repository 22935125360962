/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeprecatedHellbanResponse } from '../models/DeprecatedHellbanResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SecurityService {

    /**
     * @param email
     * @param userId
     * @returns any OK
     * @throws ApiError
     */
    public static getUser(
        email?: string,
        userId?: number,
    ): CancelablePromise<{
        ban: DeprecatedHellbanResponse;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/security/user',
            query: {
                'email': email,
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns DeprecatedHellbanResponse OK
     * @throws ApiError
     */
    public static createBan(
        userId: number,
    ): CancelablePromise<DeprecatedHellbanResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/security/user/{userId}/ban',
            path: {
                'userId': userId,
            },
        });
    }

    /**
     * @param userId
     * @returns void
     * @throws ApiError
     */
    public static deleteBan(
        userId: number,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/security/user/{userId}/ban',
            path: {
                'userId': userId,
            },
        });
    }

}
