interface Props {
  message: string;
  className?: string;
}

const SuccessMessage = ({ className, message }: Props): JSX.Element | null => (
  <div className={`Feedback Feedback--positive ${className}`} role="alert" data-test-id="form-success-message">
    <div className="Feedback-icon">
      <svg viewBox="0 0 960 960" role="presentation" className="Icon">
        <path d="M915 178.9c8 6.7 12.3 15.5 13 26.5s-2.3 21.2-9 30.5c-252.7 360.7-389.3 555.3-410 584-21.3 28.7-48.8 43.2-82.5 43.5S365 849.2 343 819.9l-278-391c-6.7-9.3-9.7-19.7-9-31s5-20.3 13-27c34-29.3 72-55.7 114-79 8.7-4.7 18.3-5.3 29-2s19.3 9.7 26 19l188 264 321-456c6.7-9.3 15.2-15.5 25.5-18.5s20.2-2.2 29.5 2.5c44 24 81.7 50 113 78z" />
      </svg>
    </div>
    <span>{message}</span>
  </div>
);

export default SuccessMessage;
