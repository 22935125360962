import { Banner, HelpText, Input, LabelButton, Status } from '@teamsnap/snap-ui';
import { Breadcrumbs, Loader } from '@teamsnap/teamsnap-ui';
import { SubscriptionInfo, TeamsSubscription } from 'core/api';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch } from 'state/hooks';
import {
  clearData as clearUserSubData,
  loadSubscriptionsForUser,
  useSubscriptionsSelector,
  useSubscriptionsStateSelector,
} from 'state/subscriptions/subscriptionSlice';
import {
  clearData as clearTeamSubData,
  loadSubscriptionsForTeam,
  loadSubscriptionsForTeamViaUser,
  useTeamSubscriptionStateSelector,
  useTeamSubscriptionsSelector,
} from 'state/subscriptions/teamSubscriptionSlice';

const SubscriptionsPage = () => {
  const [userId, setUserId] = useState<number>();
  const [teamId, setTeamId] = useState<number>();
  const [searchedUserId, setSearchedUserId] = useState<number>();
  const [searchedTeamId, setSearchedTeamId] = useState<number>();

  const dispatch = useAppDispatch();
  const subscriptionsState = useSubscriptionsStateSelector();
  const subscriptionsData = useSubscriptionsSelector();
  const teamSubscriptionState = useTeamSubscriptionStateSelector();
  const teamSubscriptionsData = useTeamSubscriptionsSelector();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userIdParam = urlParams.get('userId');
    if (userIdParam) {
      const userId = parseInt(userIdParam);
      setUserId(parseInt(userIdParam));
      setSearchedUserId(parseInt(userIdParam));
      dispatch(loadSubscriptionsForUser(userId));
      dispatch(loadSubscriptionsForTeamViaUser(userId));
    }
    if (urlParams.get('teamId')) {
      const teamId = parseInt(urlParams.get('teamId') || '');
      setTeamId(teamId);
      setSearchedTeamId(teamId);
      dispatch(loadSubscriptionsForTeam(teamId.toString()));
    }
  }, [dispatch]);

  const buildSubscriptionCard = (subscription: SubscriptionInfo) => {
    const eventDate =
      subscription.premium?.renewDate || subscription.trial?.chargeDate || subscription.promotional?.expirationDate;
    const formattedDate = eventDate ? new Date(eventDate).toLocaleDateString() : '';
    const renewPill = (
      <Status
        state={subscription.willRenew ? 'success' : 'negative'}
        text={`${subscription.willRenew ? 'renews' : 'expires '} ${formattedDate}`}
      />
    );
    const billingIssuesDetectedAt = subscription.premium?.billingIssuesDetectedAt
      ? new Date(subscription.premium.billingIssuesDetectedAt).toLocaleDateString()
      : undefined;
    const dateCreated = new Date(subscription.initialPurchaseDate).toLocaleDateString();
    return (
      <div
        className="sui-my-2 sui-p-2 sui-border sui-rounded sui-border-solid sui-border-gray-80 sui-bg-neutral-background"
        data-testid="subscription-item"
      >
        <h4 className="sui-mb-1 sui-text-center">{subscription.displayName}</h4>
        <p>
          <strong>ID:</strong> {subscription.id}
        </p>
        <p>
          <strong>Type:</strong>{' '}
          <Status state={subscription.type === 'trial' ? 'warning' : 'info'} text={subscription.type} />
        </p>
        <p>
          <strong>Platform:</strong> {subscription.platform}
        </p>

        <p>
          <strong>Initial purchase date:</strong> {dateCreated}
        </p>
        <p>
          <strong>Auto Renew:</strong> {renewPill}
        </p>
        <p>
          <strong>Price:</strong> {subscription.displayPrice} / {subscription.billingFrequency}
        </p>
        <HelpText>Price shown is accurate for US based customers only</HelpText>
        {billingIssuesDetectedAt && (
          <p className="sui-mt-1">
            <Status text={`Billing Issues Detected At: ${billingIssuesDetectedAt}`} state="negative" />
          </p>
        )}
      </div>
    );
  };

  const buildTeamSubscriptionCard = (team: TeamsSubscription) => {
    const supplier = team?.subscription?.metadata?.supplier || 'unknown';
    const supplierPill = <Status state={supplier === 'stripe' ? 'info' : 'warning'} text={supplier} />;
    const expirationPill = team.subscription.metadata.expirationDate ? (
      <Status
        state="warning"
        text={'expires ' + new Date(team.subscription.metadata.expirationDate).toLocaleDateString()}
      />
    ) : (
      <Status state="success" text="active" />
    );
    return (
      <div
        className="sui-mt-2 sui-w-full sui-p-2 sui-border sui-rounded sui-border-solid sui-border-gray-80 sui-bg-neutral-background"
        data-testid="team-subscription-item"
      >
        <h4>
          Team: {team.id} {team.name ? `(${team.name})` : ''}
        </h4>
        <p>
          <strong>Subscription:</strong> {team.subscription.metadata.name}
        </p>
        <p>
          <strong>Tier:</strong> {team.subscription.tier}
        </p>
        <p>
          <strong>Supplier:</strong> {supplierPill}
        </p>
        <p>
          <strong>Billed every:</strong> {team.subscription.metadata.frequency || 'unknown'}
        </p>
        <p>
          <strong>Status:</strong> {expirationPill}
        </p>
      </div>
    );
  };

  return (
    <div className="sui-p-3 sui-w-full" id="bulk-update-page">
      <div className="sui-mb-3">
        <Breadcrumbs
          breadcrumbs={[
            <Link to="/">All Tools</Link>,
            <Link className="sui-text-gray-8" to="/dev-actions">
              Subscriptions
            </Link>,
          ]}
        />
      </div>

      <div
        className="sui-flex sui-w-full sui-flex-row sui-flex-wrap sui-flex-gap gap sui-justify-center"
        style={{ gap: '20px' }}
      >
        {/* User Subs */}
        <div className="sui-flex sui-flex-col sui-items-center" style={{ width: '450px' }}>
          <h2 className="sui-text-center">TS+ Subscription Lookup</h2>
          <p className="sui-my-2">Using a TeamSnap User ID, search for user subscriptions</p>
          <div className="sui-my-1 sui-justify-items-center">
            <Input
              label="User ID"
              name="user_id"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserId(parseInt(e.target.value));
              }}
              placeholder=""
              value={userId?.toString() || ''}
              required
              type="number"
            />
            <LabelButton
              data-testid="search-button"
              className="sui-min-w-full sui-mt-2"
              icon="search"
              iconPosition="left"
              labelText="Search"
              sentiment="default"
              onClick={() => {
                if (userId !== undefined) {
                  setSearchedUserId(userId);
                  dispatch(clearUserSubData(subscriptionsState));
                  dispatch(loadSubscriptionsForUser(userId));
                }
              }}
              disabled={userId === undefined || isNaN(userId)}
              size="default"
              variantType="primary"
            />
          </div>

          <div className="sui-my-4">
            {subscriptionsState.processing && <Loader className="sui-mt-4" type="spin" text="Loading..." />}
            {/*show response block for the "Not Found" case, meaning no UCI (or subscriptions) exists for user */}
            {!subscriptionsState.processing &&
              (subscriptionsData || subscriptionsState.errors.join() === 'Not Found') && (
                <>
                  <h3>{subscriptionsData ? subscriptionsData.subscriptions.length : '0'} TS+ subscription(s) found:</h3>

                  <div className="sui-my-1">
                    <p>TeamSnap User ID: {searchedUserId}</p>
                    <p>UCI: {subscriptionsData?.uciUserId || '(not found)'}</p>
                  </div>

                  {subscriptionsData && (
                    <div className="sui-flex sui-flex-row sui-flex-wrap gap" style={{ gap: '20px' }}>
                      {subscriptionsData.subscriptions.map((subscription) =>
                        buildSubscriptionCard(subscription as SubscriptionInfo)
                      )}
                    </div>
                  )}
                </>
              )}
            {subscriptionsState.error && (
              <Banner sentiment="negative" title="Error" caption={subscriptionsState.errors.join(', ')} />
            )}
          </div>
        </div>

        {/* Team Subscriptions */}
        <div className="sui-flex sui-flex-col sui-items-center" style={{ width: '450px' }}>
          <h2 className="sui-text-center">Team Subscription Lookup</h2>
          <p className="sui-my-2">Search for team subscriptions by User ID or Team ID</p>
          <div className="sui-my-1 sui-justify-items-center">
            <Input
              label="Team ID"
              name="user_id"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTeamId(parseInt(e.target.value));
              }}
              placeholder=""
              value={teamId?.toString() || ''}
              type="number"
            />
            or
            <Input
              label="User ID"
              name="user_id"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setUserId(parseInt(e.target.value));
              }}
              placeholder=""
              disabled={teamId !== undefined && !isNaN(teamId)}
              value={userId?.toString() || ''}
              type="number"
            />
            <LabelButton
              data-testid="search-button"
              className="sui-min-w-full sui-mt-2"
              icon="search"
              iconPosition="left"
              labelText="Search"
              sentiment="default"
              onClick={() => {
                if (teamId !== undefined && !isNaN(teamId)) {
                  setSearchedTeamId(teamId);
                  dispatch(clearTeamSubData(teamSubscriptionState));
                  dispatch(loadSubscriptionsForTeam(teamId.toString()));
                } else if (userId !== undefined && !isNaN(userId)) {
                  setSearchedUserId(userId);
                  setSearchedTeamId(undefined);
                  dispatch(clearTeamSubData(teamSubscriptionState));
                  dispatch(loadSubscriptionsForTeamViaUser(userId));
                }
              }}
              disabled={(teamId === undefined || isNaN(teamId)) && (userId === undefined || isNaN(userId))}
              size="default"
              variantType="primary"
            />
          </div>
          {/* Team Sub search results */}
          {teamSubscriptionState.processing && <Loader className="sui-mt-4" type="spin" text="Loading..." />}
          {!teamSubscriptionState.processing && teamSubscriptionsData && teamSubscriptionsData.teams && (
            <>
              <h3>{teamSubscriptionsData ? teamSubscriptionsData.teams.length : '0'} team subscription(s) found:</h3>
              <div className="sui-my-1">
                <p>{searchedTeamId ? 'Team: ' + searchedTeamId : 'User: ' + searchedUserId}</p>
              </div>
              <div className="sui-flex sui-flex-row sui-flex-wrap gap" style={{ gap: '20px' }}>
                {teamSubscriptionsData.teams.map((team) => buildTeamSubscriptionCard(team))}
              </div>
            </>
          )}
          {teamSubscriptionState.error && (
            <Banner sentiment="negative" title="Error" caption={teamSubscriptionState.errors.join(', ')} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsPage;
