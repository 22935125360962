import * as React from 'react';
import { DateFormat, DateService } from 'frontend-toolkit';
import { orderBy } from 'lodash';
import { Registration } from 'core/api';
import { Field, Icon, PaginatedTable, Select, Breadcrumbs, Button } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { Placement } from '@teamsnap/teamsnap-ui/dist/js/types/placement';

// Local Imports
import './registrations.scss';
import { useAppDispatch } from 'state/hooks';
import {
  loadRegistrationsByDivisionId,
  loadRegistrationsByFormId,
  loadRegistrationsById,
  useRegistrationsStateSelector,
} from 'state/registrations/registrationSlice';
import { ORGANIZATION_URL } from 'core/constants';
import { useRolesSelector } from 'state/roles/roleSlice';
import { AddSupportRoleModal } from 'components/AddSupportRoleModal/AddSupportRoleModal';

const SearchSelectionValueHelperText = {
  id: "Find a registrant's registration.",
  formId: 'Find a single registration form.',
  divisionId: 'Find all registrations in this division.',
};

const columns = [
  { label: 'Order ID', name: 'orderId', isSortable: true, mods: 'u-size2of24' },
  { label: 'Registration Form', name: 'name', isSortable: true, mods: 'u-size3of24' },
  { label: 'Division ID', name: 'divisionId', isSortable: true, mods: 'u-size2of24' },
  { label: 'Registration ID', name: 'id', isSortable: true, mods: 'u-size2of24' },
  { label: 'Registration Form ID', name: 'formId', isSortable: true, mods: 'u-size3of24' },
  { label: 'Organization ID', name: 'orgId', isSortable: true, mods: 'u-size3of24' },
  { label: 'Order Date', name: 'orderDate', isSortable: true, mods: 'u-size2of24' },
  { label: 'Organization Name', name: 'orgName', isSortable: true, mods: 'u-size3of24' },
  { label: 'My Role', name: 'myRole', isSortable: false, mods: 'u-size2of24' },
  { label: 'Actions', name: 'actions', isSortable: false, mods: 'u-size4of24' },
];

export const sortColumn = (items: unknown[], sortBy: string, sortAsc: boolean) => {
  if (sortBy === 'description') {
    // ignore other chars and sort in order of which installment it is (ie the id)
    return orderBy(items, ['id'], [sortAsc ? 'asc' : 'desc']);
  }

  if (sortBy === 'dueDate' || sortBy === 'paidDate') {
    return DateService.sortStringDates(items, sortAsc, sortBy);
  }

  return orderBy(items, [sortBy], [sortAsc ? 'asc' : 'desc']);
};

export const Registrations = () => {
  const [searchSelectionValue, setSearchSelectionValue] = React.useState<string>('id');
  const [searchValue, setSearchValue] = React.useState<number>();
  const [showInternalTestRegistrations, setShowInternalTestRegistrations] = React.useState<boolean>(false);
  const [helperText, setHelperText] = React.useState<string>(SearchSelectionValueHelperText.id);

  // TODO - implement
  const [manageRolesIsOpen, setManageRolesIsOpen] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRegistration, setSelectedRegistration] = React.useState<Registration | null>(null);

  const [modalKey, setModalKey] = React.useState(0);
  const [modalOrgName, setModalOrgName] = React.useState('');

  const itemsPerPage = 10;
  const roles = useRolesSelector();

  const dispatch = useAppDispatch();

  const handleSearchById = (id: number) => dispatch(loadRegistrationsById(id));

  const handleSearchByFormId = (formId: number) => dispatch(loadRegistrationsByFormId(formId));

  const handleSearchByDivisionId = (divisionId: number) => dispatch(loadRegistrationsByDivisionId(divisionId));

  const handleSearchSelection = (e: { target: { value: string } }) => {
    const value = e.target.value.toString();

    switch (value) {
      case 'id':
        setHelperText(SearchSelectionValueHelperText.id);
        break;
      case 'formId':
        setHelperText(SearchSelectionValueHelperText.formId);
        break;
      case 'divisionId':
        setHelperText(SearchSelectionValueHelperText.divisionId);
        break;
    }

    setSearchSelectionValue(value);
  };

  const handleSearchValue = (e: { target: { value: number } }) => {
    setSearchValue(e.target.value);
  };

  // TODO - implement
  const handleManageMyRoles = (registration: Registration) => {
    setSelectedRegistration(registration);
    setManageRolesIsOpen(!manageRolesIsOpen);
    setModalKey(registration.orgId);
    setModalOrgName(registration.orgName);
  };

  // TODO - implement
  const displayMyRole = (registration: Registration) => {
    if (!roles || !roles.find((role) => role.organizationId === registration.orgId && role.isTsSupportAdmin)) {
      return (
        <Button type="link" onClick={() => handleManageMyRoles(registration)}>
          Add myself
        </Button>
      );
    } else {
      return (
        <Button onClick={() => handleManageMyRoles(registration)} type="link">
          Manage my roles
        </Button>
      );
    }
  };

  const navigateToRegistrationDetails = (registration: Registration) => {
    if (registration) {
      window.open(
        `${ORGANIZATION_URL}/organizations/${registration.orgId}/registration/${registration.formId}/details`,
        '_blank'
      );
    }
  };

  const displayActions = (registration: Registration) => (
    <Button onClick={() => navigateToRegistrationDetails(registration)} type="link">
      Jump to Registration Details
    </Button>
  );

  const { data, processing } = useRegistrationsStateSelector();
  const totalTestRegistrations = Array.isArray(data)
    ? data.filter((registration) => registration.isTest === true).length
    : 0;

  const loadData = async ({
    page,
    itemsPerPage,
    filter,
    sortBy,
    sortAsc,
  }: {
    page: number;
    itemsPerPage: number;
    filter: unknown;
    sortBy: string;
    sortAsc: boolean;
  }) => {
    const rows = data ?? [];
    const filteredRows = rows.filter((row) => row.isTest === showInternalTestRegistrations);
    const startIndex = itemsPerPage * page - itemsPerPage;
    const endIndex = Math.min(filteredRows.length, startIndex + itemsPerPage);

    const sortedItems = sortColumn(filteredRows, sortBy, sortAsc);
    const paginatedItems = sortedItems.slice(startIndex, endIndex);

    return {
      data: paginatedItems,
      totalItems: filteredRows.length,
    };
  };

  const mapData = (registration: Registration, index: number) => ({
    index: index,
    orderId: registration.orderId,
    name: registration.name,
    divisionId: registration.divisionId,
    id: registration.id,
    formId: registration.formId,
    orgId: registration.orgId,
    orderDate: DateService.format(new Date(registration.orderDate), DateFormat.DD_MM_YYYY),
    orgName: registration.orgName,
    myRole: displayMyRole(registration),
    actions: displayActions(registration),
  });

  React.useEffect(() => {
    if (searchValue && searchSelectionValue) {
      switch (searchSelectionValue) {
        case 'id':
          handleSearchById(searchValue);
          break;
        case 'formId':
          handleSearchByFormId(searchValue);
          break;
        case 'divisionId':
          handleSearchByDivisionId(searchValue);
          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchSelectionValue, showInternalTestRegistrations]);

  return (
    <div className="sui-flex u-padLg u-sizeFull Registrations" data-testid="registrations">
      <div className="u-sizeFull sui-h-full">
        <div className="u-spaceBottomLg">
          <Breadcrumbs
            breadcrumbs={[
              <Link to="/">All Tools</Link>,
              <Link className="u-colorNeutral8" to="/registrations">
                Jump to Registrations
              </Link>,
            ]}
          />
        </div>

        <div className="sui-flex">
          <div className="u-size1of4 u-spaceRightSm">
            <Select
              helperText="Search by"
              inputProps={{
                onChange: (e: { target: { value: string } }) => handleSearchSelection(e),
                value: searchSelectionValue,
                'data-testid': 'registrationsSearchSelectionInput',
              }}
              options={[
                {
                  label: 'Registration ID',
                  value: 'id',
                },
                {
                  label: 'Registration Form ID',
                  value: 'formId',
                },
                {
                  label: 'Division ID',
                  value: 'divisionId',
                },
              ]}
            />
          </div>
          <div className="u-size1of3">
            <Field
              name="searchValue"
              type="input"
              formFieldProps={{
                leftIcon: <Icon className="Icon" name="search" />,
                inputProps: {
                  type: 'number',
                  onChange: handleSearchValue,
                  value: searchValue,
                  'data-testid': 'registrationsSearchValueInput',
                },
              }}
            />
          </div>
        </div>

        <small>{helperText}</small>

        <div className="u-spaceTopMd u-spaceBottomSm">
          <Field
            isDisabled={processing}
            name="showInternalTestRegistrations"
            type="checkbox"
            formFieldProps={{
              text: `Include internal test orgs' forms (${totalTestRegistrations})`,
              checked: showInternalTestRegistrations,
              onClick: () => setShowInternalTestRegistrations(!showInternalTestRegistrations),
            }}
          />
        </div>

        <div className="registrations--table_container">
          <PaginatedTable
            isLoading={processing}
            defaultItemsPerPage={itemsPerPage}
            noResultsText={'No registrations found'}
            loadData={loadData}
            mapDataToRow={mapData}
            columns={columns}
            hideRowsSelect
            paginationPlacement={Placement.Bottom}
            reloadDependency={data}
          />
        </div>
      </div>
      {!!modalKey && (
        <AddSupportRoleModal
          key={modalKey}
          isOpen={modalKey > 0}
          onClose={() => setModalKey(0)}
          organizationId={modalKey}
          organizationName={modalOrgName}
        />
      )}
    </div>
  );
};
