import { EnvironmentUtilities } from 'frontend-toolkit';
import ENVIRONMENT from './environment';

const envUtils = new EnvironmentUtilities(window.location.host);

export const IS_PROD = ENVIRONMENT.indexOf('prod') !== -1;

export const APP_URL = {
  local: 'http://localhost:5655',
  staging: `https://${envUtils.getStagingHost('admin', 'staging.admin.teamsnap.com')}`,
  prod: 'https://admin.teamsnap.com',
}[ENVIRONMENT];

export const API_URL = {
  local: 'http://localhost:5619',
  staging: `https://${envUtils.getStagingHost('admin-api', 'staging-admin-api-prime.teamsnap.com')}`,
  prod: 'https://admin-api.teamsnap.com',
}[ENVIRONMENT];

export const TEAMSNAP_URL = {
  local: 'http://localhost:3001',
  staging: `https://${envUtils.getStagingHost('go', 'staging-classic.teamsnap.com')}`,
  prod: 'https://go.teamsnap.com',
}[ENVIRONMENT];

export const LEGACY_ADMIN_URL = {
  local: 'http://localhost:3001',
  staging: `https://${envUtils.getStagingHost('go-admin', 'staging-classic-admin.teamsnap.com')}`,
  prod: 'https://go-admin.teamsnap.com',
}[ENVIRONMENT];

export const ORGANIZATION_URL = {
  local: 'http://localhost:5502',
  staging: `https://${envUtils.getStagingHost('organization', 'staging.organization.teamsnap.com')}`,
  prod: 'https://organization.teamsnap.com',
}[ENVIRONMENT];

// This time should match https://github.com/teamsnap/terraform-deployments/blob/main/{env}/gcp/cloud-scheduler/organizations-upgrade.tf, represented here in UTC
export const ORG_UPGRADE_SCHEDULED_JOB_RUNTIME = {
  local: '2021-01-01T08:45:00.000Z',
  staging: '2021-01-01T04:00:00.000Z', // 10pm CST
  prod: '2021-01-01T08:45:00.000Z', // 2:45am CST
}[ENVIRONMENT];

export const SENTRY_URL = 'https://ea999266aecb3c4fe86199c12d1868b2@o126035.ingest.us.sentry.io/4508042841489408';
