import { Breadcrumbs } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { ClearCacheButton } from './ClearCacheButton';
import { ContentCacheService } from '../../core/api';

const toolTitle = 'Clear Contentful Cache';

const ClearContentfulCachePage = () => {
  return (
    <div className="sui-p-3 sui-w-full" id="bulk-update-page">
      <div className="sui-mb-3">
        <Breadcrumbs
          breadcrumbs={[
            <Link to="/">All Tools</Link>,
            <Link className="sui-text-gray-8" to="/dev-actions">
              {toolTitle}
            </Link>,
          ]}
        />
      </div>
      <div className="sui-flex sui-flex-col sui-items-center">
        <h2 className="sui-text-center">{toolTitle}</h2>
        <div className={'sui-flex-col sui-gap-3 sui-flex sui-my-3'}>
          <ClearCacheButton cacheName={'Posts'} apiCall={ContentCacheService.clearPostCache} />
          <ClearCacheButton cacheName={'Layouts'} apiCall={ContentCacheService.clearLayoutCache} />
          <ClearCacheButton cacheName={'Groups'} apiCall={ContentCacheService.clearGroupsCache} />
        </div>
      </div>
    </div>
  );
};

export default ClearContentfulCachePage;
