import * as Sentry from '@sentry/browser';
import { Breadcrumbs, Button, Icon, Input, PaginatedTable, PopupAction } from '@teamsnap/teamsnap-ui';
import { Placement } from '@teamsnap/teamsnap-ui/dist/js/types/placement';
import { DateFormat, DateService } from 'frontend-toolkit';
import { orderBy } from 'lodash';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { MemberSeasonParticipantsService, Organization, OrganizationsService } from 'core/api';

import './organizations.scss';
import { LabelButton } from '@teamsnap/snap-ui';
import { AddSupportRoleModal } from 'components/AddSupportRoleModal/AddSupportRoleModal';
import CreateOrganizationModal, {
  EditOrganizationData,
} from 'components/CreateOrganizationModal/CreateOrganizationModal';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import SuccessMessage from 'components/shared/SuccessMessage/SuccessMessage';
import { useAppDispatch } from 'state/hooks';
import { loadOrganizationsByFilter, useOrganizationsStateSelector } from 'state/organizations/organizationSlice';

export const Organizations = () => {
  const [searchTerm, setsearchTerm] = React.useState('');
  const [showTestOrgs, setShowTestOrgs] = React.useState(false);
  const [modalKey, setModalKey] = React.useState(0);
  const [showCreateOrgModal, setShowCreateOrgModal] = React.useState(false);
  const [selectedOrganization, setSelectedOrganization] = React.useState<EditOrganizationData>();
  const [loadingOrgDetails, setLoadingOrgDetails] = React.useState(false);
  const [feedbackError, setFeedbackError] = React.useState(null as string | null);
  const [successMessage, setSuccessMessage] = React.useState(null as string | null);

  const itemsPerPage = 10;

  const dispatch = useAppDispatch();

  const searchTermInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    searchTermInputRef.current?.focus();
  }, []);

  const handleSearchBySearchTerm = (searchTerm: string) => {
    setFeedbackError(null);
    return dispatch(loadOrganizationsByFilter({ tsDivisionId: undefined, searchTerm }));
  };

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchBySearchTerm(searchTerm);
    }
  };

  const columns = [
    { label: 'Org Name', name: 'orgName', isSortable: false, mods: 'u-size2of12' },
    { label: 'Org ID', name: 'orgId', isSortable: false, mods: 'u-size1of12' },
    { label: 'Org Creation Date', name: 'orgCreationDate', isSortable: false, mods: 'u-size1of12' },
    { label: 'Product UUID', name: 'productUUID', isSortable: false, mods: 'u-size3of12' },
    { label: 'Staff Email', name: 'staffEmail', isSortable: false, mods: 'u-size2of12' },
    { label: 'Staff Name', name: 'staffName', isSortable: false, mods: 'u-size1of12' },
    { label: 'Actions', name: 'actions', isSortable: false, mods: 'u-size2of12' },
  ];

  const { data, processing } = useOrganizationsStateSelector();
  const totalTestOrganizations = data?.filter((organization) => organization.isTest === true).length;

  const displayActions = (organization: Organization) => {
    const actions = [
      {
        text: 'Add Myself',
        callback: () => {
          setModalKey(organization.id);
        },
      },
      {
        text: 'Edit Org',
        callback: async () => {
          setLoadingOrgDetails(true);
          const { staff, ...organizationData } = organization;
          const staffData = getStaffData(organization);
          try {
            setFeedbackError(null);
            const orgDetails = await OrganizationsService.getDetails(organization.id);

            const contentEntitlements: { [key: string]: boolean } = {};
            orgDetails.contentEntitlements.forEach((ce) => {
              contentEntitlements[ce.entitlementName] = ce.access;
            });

            setSelectedOrganization({
              ...organizationData,
              ...orgDetails.settings,
              tournamentOrg: orgDetails.tournamentOrg ?? undefined,
              legacyOrgs: orgDetails.legacyOrgs.map((x) => ({ label: x.name, value: x.legacyUuid })),
              additionalLegacyOrgUuids: [],
              updateAllSeasonsAddress: false,
              mailingZipCode: organization.mailingZip,
              ownerEmail: staffData.email,
              ownerFirstName: staffData.firstName || '',
              ownerLastName: staffData.lastName || '',
              newOwnerEmail: '',
              contentEntitlements: contentEntitlements,
            });
            setLoadingOrgDetails(false);
            setShowCreateOrgModal(true);
          } catch (e) {
            setFeedbackError('Problem loading organization information for edit');
            setLoadingOrgDetails(false);
            Sentry.captureException(e);
          }
        },
      },
      {
        text: 'Sync Members',
        callback: async () => {
          try {
            setFeedbackError(null);
            await MemberSeasonParticipantsService.syncSeasonParticipants({
              organizationIdOrUuid: `${organization.id}`,
            });
            setSuccessMessage('Sync Members was requested successfully.');
          } catch (e) {
            setFeedbackError('Problem in member season participants sync process.');
            Sentry.captureException(e);
          }
        },
        requiresConfirmation: true,
        confirmationText: 'Are you sure you want to sync season participations for this org?',
      },
    ];

    return (
      <div className="registration-list-actions">
        <PopupAction
          testId="registration-list-actions-component"
          direction={['down']}
          text={<Icon name="more-horiz" />}
          actions={actions}
          popupStyle={{ width: '150px' }}
          showOnHover={false}
        />
      </div>
    );
  };

  const loadData = async ({
    page,
    itemsPerPage,
  }: {
    page: number;
    itemsPerPage: number;
    filter: unknown;
    sortBy: string;
    sortAsc: boolean;
  }) => {
    const rows = data ?? [];
    const filteredRows = !showTestOrgs ? rows.filter((row) => row.isTest === showTestOrgs) : rows;
    const startIndex = itemsPerPage * page - itemsPerPage;
    const endIndex = Math.min(filteredRows.length, startIndex + itemsPerPage);

    const sortedItems = orderBy(filteredRows, ['name'], ['asc']);
    const paginatedItems = sortedItems.slice(startIndex, endIndex);

    return {
      data: paginatedItems,
      totalItems: filteredRows.length,
    };
  };

  const getStaffData = (organization: Organization) => {
    if (organization.staff === undefined || organization.staff?.length === 0) {
      return { email: '', fullName: '', firstName: '', lastName: '' };
    }

    const staff = organization.staff.find((staff) => staff.roles.map((role) => role.type).includes('owner'));

    if (!staff) return { email: '', fullName: '', firstName: '', lastName: '' };

    return {
      email: staff?.emailAddress,
      fullName: `${staff?.firstName} ${staff?.lastName}`,
      firstName: staff?.firstName,
      lastName: staff?.lastName,
    };
  };

  const onCloseModal = (reload: boolean) => {
    setShowCreateOrgModal(false);
    if (reload) {
      handleSearchBySearchTerm(searchTerm);
    }
  };

  const mapData = (organization: Organization, index: number) => {
    const staff = getStaffData(organization);

    return {
      index: index,
      orgName: organization.name,
      orgId: organization.id,
      orgCreationDate: DateService.format(new Date(organization.insertedAt), DateFormat.DD_MM_YYYY),
      productUUID: organization.uuid,
      staffEmail: staff.email,
      staffName: staff.fullName,
      actions: displayActions(organization),
    };
  };

  const modalOrganization = data?.find((organization) => organization.id === modalKey);

  return (
    <div className="Organizations">
      {showCreateOrgModal && (
        <CreateOrganizationModal show editOrganization={selectedOrganization} onClose={onCloseModal} />
      )}

      <div className="page-content u-xs-padMd u-sm-spaceTopMd u-flexExpandSides">
        <div className="page-content-header Grid u-flexAlignItemsCenter">
          <div className="u-spaceBottomLg">
            <Breadcrumbs
              breadcrumbs={[
                <Link to="/">All Tools</Link>,
                <Link className="u-colorNeutral8" to="/organizations">
                  Teamsnap For Business Orgs
                </Link>,
              ]}
            />
          </div>

          <div className="Grid u-flexJustifyBetween">
            {feedbackError ? <ErrorMessage message={feedbackError} /> : null}
            {successMessage ? <SuccessMessage message={successMessage} /> : null}
            <div className="Grid-cell u-spaceBottomMd u-flex u-spaceRightLg u-md-size5of12 u-flexJustifyStart">
              <Input
                type="text"
                name="searchTerm"
                className="u-size1of1"
                placeholder="Search by Org ID, Product UUID, Org Name, Staff Last Name, or Staff Email."
                inputProps={{
                  onKeyDown,
                  value: searchTerm,
                  ref: searchTermInputRef,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => setsearchTerm(e.target.value),
                }}
              />

              <Button
                mods="u-spaceLeftSm"
                isActive={true}
                color="primary"
                onClick={() => handleSearchBySearchTerm(searchTerm)}
              >
                Search
              </Button>
            </div>

            <LabelButton
              size="small"
              onClick={() => {
                setSelectedOrganization(undefined);
                setShowCreateOrgModal(true);
              }}
            >
              + Create organization
            </LabelButton>
          </div>

          <div className="Grid-cell u-md-size6of12">
            <div className="Checkbox u-spaceTopSm">
              <input
                type="checkbox"
                className="Checkbox-input"
                name="includeTestOrgs"
                onChange={() => setShowTestOrgs(!showTestOrgs)}
                checked={showTestOrgs}
              />
              <label htmlFor="addLegacySeason" className="Checkbox-label">
                Include Internal Test Orgs ({totalTestOrganizations})
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="organizations--table_container u-xs-padMd" data-testid="organizations-table">
        <PaginatedTable
          isLoading={processing || loadingOrgDetails}
          defaultItemsPerPage={itemsPerPage}
          noResultsText={'No organizations found'}
          loadData={loadData}
          mapDataToRow={mapData}
          columns={columns}
          hideRowsSelect
          paginationPlacement={Placement.Bottom}
          reloadDependency={data}
        />
      </div>
      {!!modalOrganization && !!modalKey && (
        <AddSupportRoleModal
          key={modalKey}
          isOpen={modalKey > 0}
          onClose={() => setModalKey(0)}
          organizationId={modalOrganization.id}
          organizationName={modalOrganization.name}
        />
      )}
    </div>
  );
};
