import { Button, Field, Icon } from '@teamsnap/teamsnap-ui';
import React from 'react';
import './MultiSelectPicker.scss';

export interface MultiSelectPickerOption {
  value: string;
  label: string;
  disabled?: boolean;
}

export const MultiSelectPicker = ({
  index = 0,
  options: unfilteredOptions,
  onUpdateSelectedOptions,
  selected,
}: {
  index?: number;
  options: MultiSelectPickerOption[];
  onUpdateSelectedOptions: (selectedOptions: string[]) => void;
  selected?: string[];
}) => {
  const [showOptions, toggleShowOptions] = React.useState<boolean>(false);
  const [searchText, setSearchText] = React.useState<string>('');
  const [selectedOptions, setSelectedOptions] = React.useState<MultiSelectPickerOption[]>([]);
  const selectAllRef = React.useRef<HTMLInputElement>(null);
  const options = searchText
    ? unfilteredOptions.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()))
    : unfilteredOptions;

  React.useEffect(() => {
    onUpdateSelectedOptions(selectedOptions.map((option) => option.value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOptions]);

  React.useEffect(() => {
    if (selected && selected.length > 0 && options.length > 0) {
      setSelectedOptions(options.filter((option) => selected.includes(option.value)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBodyClick = React.useCallback((e) => {
    e.stopPropagation();
    const isTargetingPopup = e.target.closest('.multiselect-picker-container') != null;
    if (!isTargetingPopup) {
      toggleShowOptions(false);
    }
  }, []);

  React.useEffect(() => {
    if (!showOptions) {
      window.removeEventListener('click', handleBodyClick, false);
    } else {
      window.addEventListener('click', handleBodyClick, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOptions]);

  const handleSelectAllClick = () => {
    if (selectAllRef?.current?.checked) {
      setSelectedOptions(options);
    } else {
      setSelectedOptions(options.filter((x) => x.disabled));
    }
  };

  return (
    <div className="multiselect-picker-container">
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className="multiselect-picker-selected"
        onClick={() => toggleShowOptions(true)}
        onKeyUp={() => toggleShowOptions(true)}
      >
        {selectedOptions.map((option: MultiSelectPickerOption) => (
          <div
            className={`selected selected-${option.value.replace(/ /g, '')}-${index}`}
            key={`selected-${option.value.replace(/ /g, '')}-${index}`}
          >
            <span title={option.label}>{option.label}</span>
            {!option.disabled && (
              <button
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedOptions(selectedOptions.filter((e) => e !== option));
                }}
              >
                <Icon name="dismiss" />
              </button>
            )}
          </div>
        ))}
        {selectedOptions.length === 0 && <span className="placeholder">Select</span>}
      </div>
      {showOptions && (
        <div className="multiselect-picker-options" key={`options-${index}`}>
          <div className="FieldGroup u-borderBottom u-spaceBottomMd u-padMd">
            <Field
              formFieldProps={{
                rightIcon: <Icon name="search" />,
                inputProps: {
                  onChange: (e: any) => {
                    setSearchText(e.target.value);
                  },
                  placeholder: 'Search by',
                  value: searchText,
                },
              }}
              type="input"
              id="multiselect-search"
              name="multiselect-search"
            />
          </div>
          <div className="options">
            <div className="Checkbox u-borderBottom u-spaceBottomMd">
              <input
                ref={selectAllRef}
                className="multiselect-picker-all u-padRightXs Checkbox-input Checkbox--inline Checkbox--large"
                aria-checked="true"
                tabIndex={0}
                type="checkbox"
                id="multiselect-picker-all"
                name="multiselect-picker-all"
                onClick={handleSelectAllClick}
              />
              <label className="Checkbox-label" htmlFor="multiselect-picker-all">
                Select all
              </label>
            </div>
            {options.map((option) => (
              <div className="Checkbox" key={`option-${option.value.replace(/ /g, '')}-${index}`}>
                <input
                  tabIndex={0}
                  type="checkbox"
                  aria-checked="true"
                  value={option.value}
                  disabled={option.disabled}
                  id={`multiselect-picker-${option.value.replace(/ /g, '')}`}
                  name={`multiselect-picker-${option.value.replace(/ /g, '')}`}
                  checked={selectedOptions.some((o) => o.value === option.value)}
                  className={`multiselect-picker-${option.value.replace(
                    / /g,
                    ''
                  )} u-padRightXs Checkbox-input Checkbox--inline Checkbox--large`}
                  onChange={(e) => {
                    if (selectedOptions.some((o) => o.value === option.value)) {
                      setSelectedOptions(selectedOptions.filter((e) => e.value !== option.value));
                    } else {
                      setSelectedOptions([...selectedOptions, option]);
                    }
                  }}
                />
                <label className="Checkbox-label" htmlFor={`multiselect-picker-${option.value.replace(/ /g, '')}`}>
                  {option.label}
                </label>
              </div>
            ))}
          </div>

          <div className="actions">
            <Button
              mods="btn-clear"
              onClick={() => {
                setSelectedOptions(options.filter((x) => x.disabled));
                if (selectAllRef?.current) {
                  selectAllRef.current.checked = false;
                }
              }}
            >
              Clear
            </Button>
            <Button
              mods="btn-save"
              onClick={() => {
                toggleShowOptions(false);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
