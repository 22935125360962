/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SyncSeasonParticipationParams } from '../models/SyncSeasonParticipationParams';
import type { SyncSeasonParticipationResponse } from '../models/SyncSeasonParticipationResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MemberSeasonParticipantsService {

    /**
     * @param requestBody
     * @returns SyncSeasonParticipationResponse OK
     * @throws ApiError
     */
    public static syncSeasonParticipants(
        requestBody: SyncSeasonParticipationParams,
    ): CancelablePromise<SyncSeasonParticipationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/member_season_participants/sync_season_participants',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
