/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleType } from '../models/RoleType';
import type { SaveSupportRoleParams } from '../models/SaveSupportRoleParams';
import type { SupportRole } from '../models/SupportRole';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RolesService {

    /**
     * @returns RoleType OK
     * @throws ApiError
     */
    public static listRoles(): CancelablePromise<Array<RoleType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
        });
    }

    /**
     * @param organizationId
     * @returns SupportRole OK
     * @throws ApiError
     */
    public static listSupportRoles(
        organizationId: number,
    ): CancelablePromise<Array<SupportRole>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles/supportRoles',
            query: {
                'organizationId': organizationId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns SupportRole OK
     * @throws ApiError
     */
    public static updateSupportRoles(
        requestBody: SaveSupportRoleParams,
    ): CancelablePromise<Array<SupportRole>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/roles/supportRoles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param roleId
     * @returns any OK
     * @throws ApiError
     */
    public static deleteRole(
        roleId: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/roles/{roleID}/delete',
            path: {
                'roleID': roleId,
            },
        });
    }

}
