import './ButtonCard.scss';

export interface ButtonCardProps {
  title: string;
  onClick: () => void;
  subtitle?: string;
  mods?: string;
}

export const ButtonCard = ({ title, subtitle, onClick, mods }: ButtonCardProps) => (
  <button className={`ButtonCard  ${mods}`} {...{ onClick }}>
    <div className="ButtonCard--title">{title}</div>
    <div className="ButtonCard--subtitle">{subtitle}</div>
  </button>
);
