import { LabelButton } from '@teamsnap/snap-ui';
import type { DeprecatedHellbanResponse } from 'core/api';

/**
 * DeprecatedBanDetails component is used to display the ban details and provide UI actions to
 * delete the ban.
 */
export function DeprecatedBanDetails(props: {
	ban: DeprecatedHellbanResponse;
	deleteBan: (banId: number) => void;
}) {
	const { ban, deleteBan } = props;
	if (!ban || !ban.id) {
		// There is no ban to display.
		return (
			<div>
				<h3>No ban found</h3>
			</div>
		);
	}
	return (
		<div>
			<h3>Ban details</h3>
			<div>
				<p>User ID: {ban.snapiId}</p>
				<p>Score: {ban.score}</p>
			</div>
			<div>
				<LabelButton
					labelText="Delete ban"
					onClick={() => deleteBan(Number.parseInt(ban.snapiId, 10))}
					data-testid="delete-ban-button"
					className="sui-my-2 sui-w-16"
					icon="delete"
					iconPosition="left"
					sentiment="negative"
					size="default"
					variantType="primary"
				/>
			</div>
		</div>
	);
}
