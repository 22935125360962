import React, { useEffect, useRef } from 'react';
import { ButtonCard } from 'components/shared/ButtonCard/ButtonCard';
import { useAppNavigate } from 'state/hooks';

import { Field, Icon } from '@teamsnap/teamsnap-ui';
import { NavigateFunction } from 'react-router-dom';
import { LEGACY_ADMIN_URL } from 'core/constants';

interface AdminTool {
  title: string;
  subtitle: string;
  path: string;
}

interface AdminSection {
  sectionTitle: string;
  sectionTools: AdminTool[];
}

const ADMIN_TOOLS = [
  {
    sectionTitle: 'Primary Tools',
    sectionTools: [
      {
        title: 'Users',
        subtitle: 'Search for Users',
        path: `${LEGACY_ADMIN_URL}/admin/users`,
      },
      {
        title: 'Subscriptions',
        subtitle: 'Check a team or user\'s subscription details',
        path: '/subscriptions',
      },
      {
        title: 'Billing Transactions',
        subtitle: 'View billing invoice data',
        path: '/billing',
      },
      {
        title: 'Teams',
        subtitle: 'Search for Teams',
        path: `${LEGACY_ADMIN_URL}/admin/teams`,
      },
      {
        title: 'C&L v1 Search',
        subtitle: 'Search for Leagues',
        path: `${LEGACY_ADMIN_URL}/admin/leagues`,
      },
      {
        title: 'TeamSnap For Business Orgs',
        subtitle: 'Create/edit Org, Add myself to Org',
        path: '/organizations',
      },
      {
        title: 'Clear Unread Badge Count',
        subtitle: 'Tool for clearing badge counts for users',
        path: `${LEGACY_ADMIN_URL}/admin/clear_unread_badge_count`,
      },
      {
        title: 'Add Users to Team Chat',
        subtitle: 'Tool for adding users to a team\'s team chat.',
        path: `${LEGACY_ADMIN_URL}/admin/add_users_to_team_chat`,
      },
    ],
  },
  {
    sectionTitle: 'Other Tools',
    sectionTools: [
      {
        title: 'Merge Users',
        subtitle: 'Merge 2 users together',
        path: `${LEGACY_ADMIN_URL}/admin/prepare_merge_users`,
      },
      {
        title: 'Jump to Registrations (Reg 2)',
        subtitle: 'Jump to registration forms or orders',
        path: '/registrations',
      },
      {
        title: 'Jump to Registrations (Reg 1)',
        subtitle: 'Jump to registration forms or orders',
        path: `${LEGACY_ADMIN_URL}/admin/jump_to_registration`,
      },
      {
        title: 'Invitation Inspector',
        subtitle: 'Check invitation status',
        path: `${LEGACY_ADMIN_URL}/admin/invitation_inspector`,
      },
      {
        title: 'Add Roster to Team',
        subtitle: 'Add a roster to a team',
        path: `${LEGACY_ADMIN_URL}/admin/add_commish_to_archived_team`,
      },
      {
        title: 'Unstick League Roster Import',
        subtitle: 'Tool to unstick a league roster import',
        path: `${LEGACY_ADMIN_URL}/admin/unstick_roster_imports`,
      },
      {
        title: 'Unarchive Division',
        subtitle: 'Undo an archive for a division',
        path: `${LEGACY_ADMIN_URL}/admin/unarchive_division`,
      },
      {
        title: 'Archive Division',
        subtitle: 'Archive a division',
        path: `${LEGACY_ADMIN_URL}/admin/archive_division`,
      },
      {
        title: 'Hockey Canada Admin',
        subtitle: 'Set up Hockey Canada info',
        path: `${LEGACY_ADMIN_URL}/admin/hockey_ca_admin`,
      },
      {
        title: 'Ad Exlusions',
        subtitle: 'View, create, and delete ad exclusions',
        path: `${LEGACY_ADMIN_URL}/admin/ad_exclusions`,
      },
      {
        title: 'Unstick Team Archive',
        subtitle: 'Tool for fixing a stuck team archive',
        path: `${LEGACY_ADMIN_URL}/admin/unstick_team_archive`,
      },
      {
        title: 'Clear CC and Disable Accounts',
        subtitle: ' Clear Credit Card info from fraud users',
        path: `${LEGACY_ADMIN_URL}/admin/clear_cc_and_disable_accounts`,
      },
      {
        title: 'Reset Password',
        subtitle: 'Reset a user password',
        path: `${LEGACY_ADMIN_URL}/admin/password_reset`,
      },
      {
        title: 'Bulk Upgrade Tool',
        subtitle: 'Upload a csv for upgrade to TeamSnap for Business',
        path: '/bulk-upgrade',
      },
      {
        title: 'Clear Contentful Cache',
        subtitle: 'Clear Contentful cache of posts, layouts and groups',
        path: '/clear-contentful-cache',
      },
    ],
  },
  {
    sectionTitle: 'Developer Tools',
    sectionTools: [
      {
        title: 'Developer Actions',
        subtitle: 'Operation actions for developers',
        path: '/dev-actions',
      },
    ],
  },
  {
    sectionTitle: 'Security',
    sectionTools: [
      {
        title: 'User actions',
        subtitle: 'Review and take actions on user security',
        path: '/security/users',
      },
    ],
  },
];

const buildAdminToolCard = (adminTool: AdminTool, navigate: NavigateFunction) => {
  return (
    <ButtonCard
      key={adminTool.title}
      onClick={() =>
        adminTool.path.startsWith('/') ? navigate(adminTool.path) : (window.location.href = adminTool.path)
      }
      title={adminTool.title}
      subtitle={adminTool.subtitle}
      mods="sui-mt-3"
    />
  );
};

export const Home = () => {
  const navigate = useAppNavigate();
  const [filterValue, setFilterValue] = React.useState<string>('');
  const filterValueInput = useRef<HTMLInputElement>();

  useEffect(() => {
    filterValueInput.current?.focus();
  }, []);

  const handleFilterValue = (e: { target: { value: string } }) => {
    setFilterValue(e.target.value);
  };

  const filteredTools = ADMIN_TOOLS.reduce((acc, adminSection) => {
    const tools = adminSection.sectionTools.filter((tool) => {
      return (
        tool.title.toLowerCase().includes(filterValue.toLowerCase()) ||
        tool.subtitle.toLowerCase().includes(filterValue.toLowerCase())
      );
    });

    if (tools.length) {
      acc.push({
        ...adminSection,
        sectionTools: tools,
      });
    }

    return acc;
  }, [] as AdminSection[]);

  return (
    <div className="sui-w-full u-padXl" data-testid={'home-dashboard'}>
      <div className="sui-w-full u-padLeftXl u-padRightXl">
        <div className="sui-flex">
          <div className="u-sizeFull sui-h-full">
            <div className="u-size1of3">
              <Field
                name="filterValue"
                type="input"
                formFieldProps={{
                  leftIcon: <Icon className="Icon" name="search" />,
                  inputProps: {
                    type: 'text',
                    ref: filterValueInput,
                    onChange: handleFilterValue,
                    value: filterValue,
                    'data-testid': 'home-input-filter',
                  },
                  placeholder: 'Search for tools',
                }}
              />
            </div>
          </div>
        </div>

        {filteredTools.map((adminSection) => (
          <div key={adminSection.sectionTitle}>
            <h3 className="sui-text-left sui-mt-4">{adminSection.sectionTitle}</h3>
            <div className="sui-flex sui-flex-row sui-flex-wrap gap" style={{ gap: '20px' }}>
              {adminSection.sectionTools.map((tool) => buildAdminToolCard(tool, navigate))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
