/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ContentCacheService {

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static clearPostCache(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/content-cache/post',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static clearLayoutCache(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/content-cache/layout',
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static clearGroupsCache(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/content-cache/groups',
        });
    }

}
