import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetSubscriptionsForTeamsResponse, SubscriptionsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<GetSubscriptionsForTeamsResponse> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadSubscriptionsForTeam = createAsyncThunk('subscriptions/getByTeamId', (teamId: string) =>
  handleErrors(() => SubscriptionsService.getForTeam(teamId)),
);

export const loadSubscriptionsForTeamViaUser = createAsyncThunk('subscriptions/getByTeamId', (userId: number) =>
  handleErrors(() => SubscriptionsService.getForTeam(undefined, userId)),
);

export const slice = createSlice({
  name: 'teamSubscriptions',
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSubscriptionsForTeam.pending, asyncPendingReducer);
    builder.addCase(loadSubscriptionsForTeam.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useTeamSubscriptionStateSelector = () => useAppSelector((state) => state.teamSubscriptions);
export const useTeamSubscriptionsSelector = () => useAppSelector((state) => state.teamSubscriptions.data);

export default slice.reducer;
export const { clearData } = slice.actions;
