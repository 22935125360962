import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import organizationsReducer from './organizations/organizationSlice';
import registrationsReducer from './registrations/registrationSlice';
import programsReducer from './programs/programSlice';
import rolesReducer from './roles/roleSlice';
import subscriptionsReducer from './subscriptions/subscriptionSlice';
import teamSubscriptionsReducer from './subscriptions/teamSubscriptionSlice';
import billingReducer from './billing/billingSlice';
import securityUserReducer from './security/userSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    organizations: organizationsReducer,
    registrations: registrationsReducer,
    programs: programsReducer,
    roles: rolesReducer,
    subscriptions: subscriptionsReducer,
    teamSubscriptions: teamSubscriptionsReducer,
    billing: billingReducer,
    securityUser: securityUserReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
