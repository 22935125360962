import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncState } from 'core/types';
import {
  asyncFulfilledNoDataReducer,
  asyncFulfilledReducer,
  asyncPendingReducer,
  handleErrors,
  useAppSelector,
} from '../hooks';
import { User, UserService } from '../../core/api';

const initialState: AsyncState<User> = {
  data: null,
  processing: true,
  error: false,
  errors: [],
};

export const loadUser = createAsyncThunk('user/load', () => handleErrors(() => UserService.me()));

export const logoutUser = createAsyncThunk('user/logout', () => handleErrors(() => UserService.logout()));

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, asyncPendingReducer);
    builder.addCase(loadUser.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
    builder.addCase(logoutUser.pending, asyncPendingReducer);
    builder.addCase(logoutUser.fulfilled, (state, action) => asyncFulfilledNoDataReducer(state, action));
  },
});

export const useUserStateSelector = () => useAppSelector((state) => state.user);
export const useUserSelector = () => useAppSelector((state) => state.user.data);

export default slice.reducer;
