/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Division } from '../models/Division';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SeasonsService {

    /**
     * @param seasonId
     * @returns Division OK
     * @throws ApiError
     */
    public static list(
        seasonId: number,
    ): CancelablePromise<Array<Division>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/seasons/{seasonId}/divisions',
            path: {
                'seasonId': seasonId,
            },
        });
    }

}
