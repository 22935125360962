import { ResultState, useContentApiCall } from 'state/content/clearContentCache';
import { Icon, LabelButton } from '@teamsnap/snap-ui';
import { CancelablePromise } from '../../core/api';

const getSentiment = (resultState: ResultState) => {
  if (resultState === 'success') {
    return 'success';
  } else {
    return 'default';
  }
};

const getLabelText = (resultState: ResultState, cacheName: string) => {
  switch (resultState) {
    case 'success':
      return `${cacheName} Cleared!`;
    case 'loading':
      return `Clearing ${cacheName}...`;
    default:
      return `Clear ${cacheName} Cache`;
  }
};

interface ClearCacheButtonProps {
  cacheName: string;
  apiCall: () => CancelablePromise<unknown>;
}

export const ClearCacheButton = ({ cacheName, apiCall }: ClearCacheButtonProps) => {
  const [trigger, result] = useContentApiCall(apiCall);

  return (
    <div className="sui-flex sui-flex-col sui-items-center sui-gap-1" style={{ width: '350px' }}>
      <LabelButton
        style={{ width: '100%' }}
        disabled={result.state === 'loading'}
        onClick={trigger}
        labelText={getLabelText(result.state, cacheName)}
        icon={'delete'}
        iconPosition={'left'}
        sentiment={getSentiment(result.state)}
      />
      {result.state === 'error' && (
        <div className={'sui-flex sui-items-center sui-gap-1 error-message'}>
          <Icon name={'warning'} />
          <div className={'error-message'}>{`The ${cacheName.toLowerCase()} cache did not clear successfully!`}</div>
        </div>
      )}
    </div>
  );
};
