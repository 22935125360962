import { Banner, Input, LabelButton, Status } from '@teamsnap/snap-ui';
import { Breadcrumbs, Loader } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useAppDispatch } from 'state/hooks';
import { clearData, loadTransactionForInvoiceNumber, useBillingSelector, useBillingStateSelector } from 'state/billing/billingSlice';

import { GetBillingTransactionForStripeResponse } from 'core/api';

const BillingPage = () => {
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [searchedInvoiceNumber, setSearchedInvoiceNumber] = useState<string>();

  const dispatch = useAppDispatch();
  const billingState = useBillingStateSelector();
  const billingData = useBillingSelector();

  const buildInvoiceCard = (invoice: GetBillingTransactionForStripeResponse) => {
    return (
      <div className="sui-my-2 sui-p-2 sui-border sui-rounded sui-border-solid sui-border-gray-80 sui-bg-neutral-background" data-testid='invoice-item'>
        <h3 className='sui-mb-1 sui-text-center'>{searchedInvoiceNumber}</h3>
        <p><strong>ID:</strong> {invoice.id}</p>
        <p><strong>Created:</strong> {invoice.created}</p>
        <p><strong>Team Id:</strong> {invoice.teamId}</p>
        <p><strong>Type:</strong> <Status state={invoice.type === 'purchase' ? 'info' : 'success'}>{invoice.type}</Status></p>
        <p><strong>Amount ({invoice.currency.toUpperCase()}):</strong> {invoice.amountAttempted} </p>
        <p><strong>Charged ({invoice.currency.toUpperCase()}):</strong> {invoice.amountCharged}</p>
        <p><strong>Card Ending In:</strong> *{invoice.last4} </p>
        <p><strong>Processed:</strong> {invoice.processedAt}</p>
      </div>
    );
  }

  return (
    <div className="sui-p-3 sui-w-full" id="bulk-update-page">
      <div className="sui-mb-3">
        <Breadcrumbs
          breadcrumbs={[
            <Link to="/">All Tools</Link>,
            <Link className="sui-text-gray-8" to="/dev-actions">
              Billing Transactions
            </Link>,
          ]}
        />
      </div>

      <div className="sui-flex sui-flex-col sui-items-center">
        <h2 className="sui-text-center">Stripe Billing Transactions</h2>
        <p className='sui-my-2'>Using a Stripe "invoice number", search for invoice data</p>
        <div className="sui-my-1 sui-justify-items-center">
          <Input
            className="sui-mb-2"
            label="Invoice Number"
            name="inv_num"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setInvoiceNumber(e.target.value) }}
            placeholder="xxxxxxxx-xxxx"
            value={invoiceNumber || ''}
            required
            type="text"
          />
          <LabelButton
            data-testid="search-button"
            className="sui-min-w-full"
            icon="search"
            iconPosition="left"
            labelText="Search"
            sentiment="default"
            onClick={() => {
              if (invoiceNumber !== undefined) {
                setSearchedInvoiceNumber(invoiceNumber.trim());
                dispatch(clearData(billingState));
                dispatch(loadTransactionForInvoiceNumber(invoiceNumber.trim()))
              }
            }}
            disabled={invoiceNumber === undefined || invoiceNumber.length === 0}
            size="default"
            variantType="primary"
          />
        </div>

        <div className="sui-my-4">
          {billingState.processing &&
            <Loader className='sui-mt-4' type="spin" text="Loading..." />
          }
          {!billingState.processing && billingData && (
            <div className="sui-flex sui-flex-row sui-flex-wrap gap" style={{ gap: '20px' }}>
              {buildInvoiceCard(billingData)}
            </div>
          )}
          {billingState.error &&
            <Banner
              sentiment={billingState.errors.join() === 'Not Found' ? 'warning' : 'negative'}
              title="Error"
              caption={billingState.errors.join(', ')}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
