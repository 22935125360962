const sports = [
  { value: '69', label: 'Adaptive Sports' },
  { value: '72', label: 'Alpine Skiing' },
  { value: '59', label: 'Archery' },
  { value: '26', label: 'Australian Football' },
  { value: '27', label: 'Badminton' },
  { value: '28', label: 'Bandy' },
  { value: '5', label: 'Baseball' },
  { value: '1', label: 'Basketball' },
  { value: '29', label: 'Bocce' },
  { value: '13', label: 'Bowling' },
  { value: '30', label: 'Broomball' },
  { value: '105', label: 'Camogie' },
  { value: '31', label: 'Cheerleading' },
  { value: '32', label: 'Chess' },
  { value: '54', label: 'Cow Tipping' },
  { value: '8', label: 'Cricket' },
  { value: '33', label: 'Croquet' },
  { value: '70', label: 'Cross Country' },
  { value: '71', label: 'Cross Country Skiing' },
  { value: '34', label: 'Curling' },
  { value: '35', label: 'Cycling' },
  { value: '79', label: 'Dance' },
  { value: '93', label: 'Darts' },
  { value: '77', label: 'Diving' },
  { value: '14', label: 'Dodgeball' },
  { value: '25', label: 'Dragon Boat' },
  { value: '144', label: 'Esports' },
  { value: '36', label: 'Fencing' },
  { value: '15', label: 'Field Hockey' },
  { value: '119', label: 'Figure Skating' },
  { value: '60', label: 'Floor Hockey' },
  { value: '44', label: 'Floorball' },
  { value: '90', label: 'Flying' },
  { value: '37', label: 'Foosball' },
  { value: '7', label: 'Football' },
  { value: '157', label: 'Futsal' },
  { value: '137', label: 'Gaelic Football' },
  { value: '46', label: 'Golf' },
  { value: '85', label: 'Gymnastics-Coed' },
  { value: '56', label: 'Gymnastics-Men' },
  { value: '57', label: 'Gymnastics-Women' },
  { value: '68', label: 'Handcycling' },
  { value: '76', label: 'Horseback Riding' },
  { value: '111', label: 'Horseshoes' },
  { value: '38', label: 'Hurling' },
  { value: '16', label: 'Ice Hockey' },
  { value: '39', label: 'Indoor Soccer' },
  { value: '17', label: 'Inline Hockey' },
  { value: '102', label: 'Jump Rope' },
  { value: '141', label: 'Karaoke' },
  { value: '50', label: 'Ki-O-Rahi' },
  { value: '18', label: 'Kickball' },
  { value: '10', label: 'Lacrosse' },
  { value: '128', label: 'Martial Arts' },
  { value: '134', label: 'Mixed Martial Arts' },
  { value: '88', label: 'Musical Group' },
  { value: '40', label: 'Netball' },
  { value: '52', label: 'Non-Sport Group' },
  { value: '24', label: 'Other Sport' },
  { value: '53', label: 'Outrigger' },
  { value: '19', label: 'Paintball' },
  { value: '45', label: 'Petanque' },
  { value: '63', label: 'Pickleball' },
  { value: '20', label: 'Polo' },
  { value: '99', label: 'Pool and Billiards' },
  { value: '67', label: 'Quad Rugby' },
  { value: '82', label: 'Quidditch' },
  { value: '55', label: 'Racquetball' },
  { value: '51', label: 'Ringette' },
  { value: '158', label: 'Rock Climbing' },
  { value: '48', label: 'Roller Derby' },
  { value: '21', label: 'Rowing' },
  { value: '9', label: 'Rugby' },
  { value: '41', label: 'Running' },
  { value: '47', label: 'Sailing' },
  { value: '148', label: 'Scouts' },
  { value: '160', label: 'Skiing (Nordic)' },
  { value: '65', label: 'Sled Hockey' },
  { value: '61', label: 'Slo-pitch' },
  { value: '159', label: 'Snowboarding' },
  { value: '2', label: 'Soccer' },
  { value: '4', label: 'Softball' },
  { value: '114', label: 'Squash' },
  { value: '62', label: 'Street Hockey' },
  { value: '42', label: 'Swimming' },
  { value: '116', label: 'Synchronized Figure Skating' },
  { value: '122', label: 'Synchronized Swimming' },
  { value: '43', label: 'Tennis' },
  { value: '58', label: 'Track And Field' },
  { value: '22', label: 'Ultimate' },
  { value: '6', label: 'Volleyball' },
  { value: '23', label: 'Water Polo' },
  { value: '64', label: 'Wheelchair Basketball' },
  { value: '73', label: 'Wheelchair Hockey' },
  { value: '75', label: 'Wheelchair Lacrosse' },
  { value: '66', label: 'Wheelchair Softball' },
  { value: '74', label: 'Wheelchair Volleyball' },
  { value: '11', label: 'Wiffleball' },
  { value: '49', label: 'Wrestling' },
];

export default sports;
