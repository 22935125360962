/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetSubscriptionInfoForUserResponse } from '../models/GetSubscriptionInfoForUserResponse';
import type { GetSubscriptionsForTeamsResponse } from '../models/GetSubscriptionsForTeamsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionsService {

    /**
     * @param userId
     * @returns GetSubscriptionInfoForUserResponse OK
     * @throws ApiError
     */
    public static getForUser(
        userId: number,
    ): CancelablePromise<GetSubscriptionInfoForUserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions',
            query: {
                'userId': userId,
            },
        });
    }

    /**
     * @param ids
     * @param userId
     * @returns GetSubscriptionsForTeamsResponse OK
     * @throws ApiError
     */
    public static getForTeam(
        ids?: string,
        userId?: number,
    ): CancelablePromise<GetSubscriptionsForTeamsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/subscriptions/teams',
            query: {
                'ids': ids,
                'userId': userId,
            },
        });
    }

}
