import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SecurityService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';
import { GetSecurityUser } from './types';


const initialState: AsyncState<GetSecurityUser> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadUser = createAsyncThunk('security/getUser', ({email, userId}: {email?: string, userId?: number}) =>
  handleErrors(() => SecurityService.getUser(email, userId)),
);

export const deleteBan = createAsyncThunk('security/deleteBan', ({userId}: {userId: number}) =>
  handleErrors(() => SecurityService.deleteBan(userId)),
);

export const slice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    clearData: (state, action) => {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.pending, asyncPendingReducer);
    builder.addCase(loadUser.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
    builder.addCase(deleteBan.pending, asyncPendingReducer);
    builder.addCase(deleteBan.fulfilled, (state) => {
      // Handle the fulfilled case for deleteBan, which doesn't require a response nor any data
      // to be updated, beyond the state and success of the request.
      state.processing = false;
      state.error = false;
    });
  },
});

export const useSecurityUserStateSelector = () => useAppSelector((state) => state.securityUser);
export const useSecurityUserSelector = () => useAppSelector((state) => state.securityUser.data);

export default slice.reducer;
export const { clearData } = slice.actions;
