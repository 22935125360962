import { Breadcrumbs } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';

export function SecurityBreadcrumbs() {
  return (
    <div className="sui-mb-3">
      <Breadcrumbs
        breadcrumbs={[
          <Link to="/">All Tools</Link>,
          <Link className="sui-text-gray-8" to="/security">
            Security
          </Link>,
          <Link className="sui-text-gray-8" to="/security/users">
            Users
          </Link>
        ]}
      />
    </div>
  );
}