import { LabelButton, Icon } from '@teamsnap/snap-ui';
import { Field, FieldMessage, Statuses } from '@teamsnap/teamsnap-ui';
import { useField } from 'formik';
import { ChangeEvent, useState } from 'react';
import { numberInputOnWheelPreventChange } from '../FormField/FormField';
import { unionBy } from 'lodash';

export type LookupOption = { title: string; id: string; subtitle?: string };

type Props = {
  label: string;
  name: string;
  disabled?: boolean;
  isMulti?: boolean;
  type: 'text' | 'number';
  options: LookupOption[];
  onSearch: (x: string) => Promise<string | undefined>;
};

const LookupField = ({ label, isMulti, onSearch, options, ...props }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchError, setSearchError] = useState<string | undefined>();
  const [field, _, { setValue }] = useField<string | string[]>(props);
  const [selectedOptions, setSelectedOptions] = useState<LookupOption[]>([]);

  const { name, type } = props;
  const value = isMulti ? (field.value as string[]) : `${field.value || ''}`;
  const searchDisabled = props.disabled || (!isMulti && !!value);

  const search = () => onSearch(searchTerm).then(setSearchError);

  const onSelectOption = (id: string) => {
    if (props.disabled) return;

    if (!isMulti) {
      setValue(value === id ? '' : id);
      return;
    }

    const items = value as string[];
    setValue(items.includes(id) ? items.filter((x) => x !== id) : [...items, id]);
    setSelectedOptions(
      items.includes(id)
        ? selectedOptions.filter((x) => x.id !== id)
        : [...selectedOptions, options.find((x) => x.id === id) as LookupOption]
    );
  };

  return (
    <div>
      <label htmlFor={name} className="sui-label">
        {label}
      </label>

      <div className="sui-flex sui-mt-1">
        <Field
          isDisabled={searchDisabled}
          style={{ flex: '1 1 auto' }}
          type={type}
          name={name}
          status={searchError ? 'error' : 'success'}
          formFieldProps={{
            leftIcon: <Icon name="search" />,
            inputProps: {
              type,
              value: searchTerm,
              onWheel: type === 'number' ? numberInputOnWheelPreventChange : undefined,
              onChange: (e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value),
              id: `${name}-search-input`,
              'data-testid': `${name}-search-input`,
            },
          }}
        />

        <LabelButton disabled={searchDisabled} size="small" className="sui-ml-1" onClick={search}>
          Search
        </LabelButton>
      </div>

      <FieldMessage mods="sui-caption" status={Statuses.ERROR}>
        {searchError}
      </FieldMessage>

      <div className="sui-mt-2 sui-flex sui-justify-center">
        {unionBy(selectedOptions, options, 'id').map(({ id, title, subtitle }) => {
          const selected = isMulti ? value.includes(id) : value === id;

          return (
            <div
              tabIndex={0}
              role="button"
              key={id}
              onClick={() => onSelectOption(id)}
              onKeyUp={(e) => e.key === 'Enter' && onSelectOption(id)}
              className="sui-flex sui-ml-2 sui-bg-neutral-background sui-justify-between sui-border-gray-80 sui-rounded sui-p-2 sui-cursor-pointer sui-items-center"
            >
              <div>
                <p className="sui-text-desktop-5">{title}</p>
                <p className="sui-text-gray-40 sui-text-desktop-3">{subtitle}</p>
              </div>
              <div className={`sui-p-1 sui-ml-2 sui-text-${selected ? 'blue-40 sui-bg-gray-98' : 'gray-40'}`}>
                <p className="sui-flex sui-text-desktop-4">
                  {selected && <Icon name="check" size="s" />} Select{selected ? 'ed' : ''}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LookupField;
