import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProgramType, ProgramsService } from 'core/api';
import { AsyncState } from 'core/types';
import { asyncFulfilledReducer, asyncPendingReducer, handleErrors, useAppSelector } from 'state/hooks';

const initialState: AsyncState<ProgramType[]> = {
  data: null,
  processing: false,
  error: false,
  errors: [],
};

export const loadProgramsByOrgId = createAsyncThunk('programs/loadById', (id: number) =>
  handleErrors(() => ProgramsService.list(id)),
);

export const slice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProgramsByOrgId.pending, asyncPendingReducer);
    builder.addCase(loadProgramsByOrgId.fulfilled, (state, action) => asyncFulfilledReducer(state, action));
  },
});

export const useProgramsStateSelector = () => useAppSelector((state) => state.programs);
export const useProgramsSelector = () => useAppSelector((state) => state.programs.data);

export default slice.reducer;
