import './devActions.scss';
import { LabelButton } from '@teamsnap/snap-ui';
import { Breadcrumbs } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { OrganizationUpgradeBatch, OrganizationUpgradesService } from 'core/api';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { Status } from '../BulkUpdate/BulkUpdate';

const DevActionsPage = () => {
  const [latestBatch, setLatestBatch] = useState<OrganizationUpgradeBatch | null>(null);
  const [status, setStatus] = useState<Status>('completed');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    OrganizationUpgradesService.latest().then((latestBatch) => {
      if (latestBatch) {
        setLatestBatch(latestBatch);
        setStatus(latestBatch.status as Status);
      }
    });
  }, []);

  const onSuspendBatch = () => {
    setErrorMessage('');
    OrganizationUpgradesService.suspendProcessing()
    .then(() => setStatus('suspended'))
    .catch(() =>
      setErrorMessage("Sorry, we couldn't suspend this batch. Try again")
    );
  };

  const onRestartBatch = () => {
    setErrorMessage('');
    OrganizationUpgradesService.restartSuspended()
    .then(() => setStatus('processing'))
    .catch(() =>
      setErrorMessage("Sorry, we couldn't restart the suspended batch. Try again")
    );
  };

  return (
    <div className="u-padLg sui-w-full" id="bulk-update-page">
      <div className="u-spaceBottomLg">
        <Breadcrumbs
          breadcrumbs={[
            <Link to="/">All Tools</Link>,
            <Link className="u-colorNeutral8" to="/dev-actions">
              Developer Actions
            </Link>,
          ]}
        />
      </div>

      <div className="sui-flex sui-flex-col sui-items-center">
        <ErrorMessage className={errorMessage ? '' : 'sui-hidden'} message={errorMessage} />

        <section className="container">
          <div>
            <h2 className="sui-text-desktop-6 sui-text-center">Bulk Org Upgrade Actions</h2>
            <div>
              <p className="sui-text-desktop-2 sui-text-center sui-mt-1">
                For scheduling and download logs use the {' '}
                <Link className="u-colorNeutral8" to="/bulk-upgrade">
                Bulk Upgrade Tool
              </Link>
              </p>

            </div>
          </div>

          {status === 'processing' && (
            <>
              <LabelButton
                icon="close"
                variantType="tertiary"
                sentiment="negative"
                labelText={'Suspend in progress Batch ID ' + latestBatch?.id}
                onClick={onSuspendBatch}
              />
              <p className="sui-text-desktop-2 sui-text-center sui-mt-1">
                This batch was uploaded{' '}
                {new Date(latestBatch?.uploadedAt || '').toLocaleString('en-US', { timeZoneName: 'short' })} and
                contains {latestBatch?.numberOfUuids} UUIDs
              </p>
            </>
          )}

          {status === 'suspended' && (
            <>
              <LabelButton
                type="submit"
                className="sui-mt-2"
                labelText="Restart Suspended Batch"
                onClick={onRestartBatch}
              />
              <p className="sui-text-desktop-2 sui-text-center sui-mt-1">
                This batch was uploaded{' '}
                {new Date(latestBatch?.uploadedAt || '').toLocaleString('en-US', { timeZoneName: 'short' })} and
                contains {latestBatch?.numberOfUuids} UUIDs.
              </p>
            </>
          )}

        {status !== 'suspended' && status !== 'processing' && (
            <p className="sui-text-desktop-2 sui-text-center sui-mt-1">
            No actions available for the latest batch id{' '}
            {latestBatch?.id} - status is {latestBatch?.status}.
          </p>
        )}
        </section>
      </div>
    </div>
  );
};

export default DevActionsPage;
