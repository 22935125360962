/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubscriptionPlatform {
    UNKNOWN = 'unknown',
    IOS = 'ios',
    ANDROID = 'android',
    PROMOTIONAL = 'promotional',
    WEB = 'web',
}
