/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Organization } from '../models/Organization';
import type { OrganizationAvailableEntitlement } from '../models/OrganizationAvailableEntitlement';
import type { OrganizationCreate } from '../models/OrganizationCreate';
import type { OrganizationDetails } from '../models/OrganizationDetails';
import type { OrganizationSettings } from '../models/OrganizationSettings';
import type { OrganizationStubInfo } from '../models/OrganizationStubInfo';
import type { OrganizationUpdate } from '../models/OrganizationUpdate';
import type { SeasonType } from '../models/SeasonType';
import type { TournamentOrganizationInfo } from '../models/TournamentOrganizationInfo';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationsService {

    /**
     * @param tsDivisionId
     * @param searchTerm
     * @returns Organization OK
     * @throws ApiError
     */
    public static get(
        tsDivisionId?: number,
        searchTerm?: string,
    ): CancelablePromise<Array<Organization>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations',
            query: {
                'tsDivisionId': tsDivisionId,
                'searchTerm': searchTerm,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns Organization OK
     * @throws ApiError
     */
    public static createOrganization(
        requestBody: OrganizationCreate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organizations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @param organizationId
     * @param requestBody
     * @returns Organization OK
     * @throws ApiError
     */
    public static updateOrganization(
        organizationId: number,
        requestBody: OrganizationUpdate,
    ): CancelablePromise<Organization> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organizations/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @param tournamentOrgId
     * @param checkInUse
     * @returns TournamentOrganizationInfo OK
     * @throws ApiError
     */
    public static getTournamentOrganization(
        tournamentOrgId: number,
        checkInUse?: boolean,
    ): CancelablePromise<TournamentOrganizationInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/tournament_organization/{tournamentOrgId}',
            path: {
                'tournamentOrgId': tournamentOrgId,
            },
            query: {
                'checkInUse': checkInUse,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @param tsPersistentUuid
     * @param checkInUse
     * @returns OrganizationStubInfo OK
     * @throws ApiError
     */
    public static getOrgStubByTsPersistentUuid(
        tsPersistentUuid: string,
        checkInUse?: boolean,
    ): CancelablePromise<OrganizationStubInfo> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/legacy_org_info/{tsPersistentUuid}',
            path: {
                'tsPersistentUuid': tsPersistentUuid,
            },
            query: {
                'checkInUse': checkInUse,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * @returns OrganizationAvailableEntitlement OK
     * @throws ApiError
     */
    public static getEntitleMents(): CancelablePromise<Array<OrganizationAvailableEntitlement>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/entitlements',
        });
    }

    /**
     * @param organizationId
     * @returns OrganizationSettings OK
     * @throws ApiError
     */
    public static getSettings(
        organizationId: number,
    ): CancelablePromise<OrganizationSettings> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/settings',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @param organizationId
     * @returns OrganizationDetails OK
     * @throws ApiError
     */
    public static getDetails(
        organizationId: number,
    ): CancelablePromise<OrganizationDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/details',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @param organizationId
     * @param isArchived
     * @param legacySeasons
     * @returns SeasonType OK
     * @throws ApiError
     */
    public static getSeasons(
        organizationId: number,
        isArchived?: boolean,
        legacySeasons?: string,
    ): CancelablePromise<Array<SeasonType>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organizations/{organizationId}/seasons',
            path: {
                'organizationId': organizationId,
            },
            query: {
                'isArchived': isArchived,
                'legacySeasons': legacySeasons,
            },
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
