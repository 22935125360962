import { useState } from 'react';

const useRestRequest = <T>(promise: Promise<T>) => {
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState(true);

  promise
    .then(setData)
    .catch(setError)
    .finally(() => setLoading(false));

  return { data, error, loading };
};

export default useRestRequest;
