import './bulkUpdate.scss';
import * as Yup from 'yup';
import Papa from 'papaparse';
import { LabelButton } from '@teamsnap/snap-ui';
import { Breadcrumbs } from '@teamsnap/teamsnap-ui';
import { Link } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { FormField, FormFieldType } from 'components/shared';
import { useEffect, useRef, useState, useCallback } from 'react';
import { OrganizationUpgradeBatch, OrganizationUpgradeCreate, OrganizationUpgradesService } from 'core/api';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { ORG_UPGRADE_SCHEDULED_JOB_RUNTIME } from 'core/constants';

export type Status = 'processing' | 'scheduled' | 'canceled' | 'failed' | 'completed' | 'suspended';

const initialFormValues = { from: '', to: '' };
const validationSchema = Yup.object().shape({
  from: Yup.string().required('Please, select a date.'),
  to: Yup.string().required('Please, select a date.'),
});

const scheduledBatchRunTime = new Date(ORG_UPGRADE_SCHEDULED_JOB_RUNTIME).toLocaleTimeString('en-US', {
  timeZoneName: 'short',
});

const registrationAllowedValues = ['Reg 2', 'Reg 1', 'Both'];
const registrationError = `Invalid Registration type. Allowed values are: ${registrationAllowedValues.join(', ')}`;
const pricingPlanAllowedValues = [
  'TeamSnap for Business',
  'TeamSnap for Business with Website Toolkit',
  'TeamSnap for Business with Competition Management',
  'TeamSnap for Business with Competition Management and Website Toolkit',
];
const planError = `Invalid Pricing Plan. Allowed values are: ${pricingPlanAllowedValues.join(', ')}`;
const requiredColumns = ['UUID', 'Pricing Plan', 'Registration'];
const historicalSeasonsError = 'Please, input Yes or No to Include Historical Seasons';
const optionalColumns = [
  'TST ID',
  'Website URL',
  'Address 1',
  'Address 2',
  'City',
  'State/Province',
  'Zip/Postal Code',
  'Player Count',
  'Include Historical Seasons',
];

const convertAndValidateCSV = (file: File): Promise<OrganizationUpgradeCreate['organizationUpgrades']> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const [headerColumns, ...rows] = Papa.parse<string[]>(e.target?.result as string).data;

      const missingRequiredColumns = requiredColumns.filter((col) => !headerColumns.includes(col));
      if (missingRequiredColumns.length > 0) {
        reject([`Required columns are missing: ${missingRequiredColumns.join(', ')}`]);
      }

      const unknownColumns = headerColumns.filter(
        (col) => !requiredColumns.includes(col) && !optionalColumns.includes(col)
      );

      if (unknownColumns.length > 0) {
        reject([`Unknown columns were included: ${unknownColumns.join(', ')}`]);
      }

      const errors: string[] = [];
      const batch = rows.map((org) => {
        const legacyPersistentUuid = org[headerColumns.indexOf('UUID')];
        const playerCount = org[headerColumns.indexOf('Player Count')];
        const tournamentOrganizationId = org[headerColumns.indexOf('TST ID')];
        const registrationType = org[headerColumns.indexOf('Registration')];
        const plan = org[headerColumns.indexOf('Pricing Plan')];
        const includeHistoricalSeasons = org[headerColumns.indexOf('Include Historical Seasons')].toUpperCase();

        if (
          (!legacyPersistentUuid ||
            isNaN(legacyPersistentUuid as unknown as number) ||
            (playerCount && isNaN(playerCount as unknown as number)) ||
            (tournamentOrganizationId && isNaN(tournamentOrganizationId as unknown as number))) &&
          !errors.includes('Please, check all the numeric fields')
        ) {
          errors.push('Please, check all the numeric fields');
        }

        if (!registrationAllowedValues.includes(registrationType) && !errors.includes(registrationError)) {
          errors.push(registrationError);
        }

        if (!pricingPlanAllowedValues.includes(plan) && !errors.includes(planError)) {
          errors.push(planError);
        }

        if (
          includeHistoricalSeasons &&
          !['YES', 'NO'].includes(includeHistoricalSeasons) &&
          !errors.includes(historicalSeasonsError)
        ) {
          errors.push(historicalSeasonsError);
        }

        return {
          registrationType,
          plan,
          legacyPersistentUuid: legacyPersistentUuid,
          playerCount: playerCount ? Number.parseInt(playerCount) : null,
          includeHistoricalSeasons: includeHistoricalSeasons === 'NO' ? false : true,
          tournamentOrganizationId: tournamentOrganizationId ? Number.parseInt(tournamentOrganizationId) : null,
          websiteUrl: org[headerColumns.indexOf('Website URL')],
          street1: org[headerColumns.indexOf('Address 1')],
          street2: org[headerColumns.indexOf('Address 2')],
          city: org[headerColumns.indexOf('City')],
          state: org[headerColumns.indexOf('State/Province')],
          postalCode: org[headerColumns.indexOf('Zip/Postal Code')],
        };
      });

      errors.length ? reject(errors) : resolve(batch);
    };

    reader.readAsText(file);
  });

const BulkUpdatePage = () => {
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [latestBatch, setLatestBatch] = useState<OrganizationUpgradeBatch | null>(null);
  const [status, setStatus] = useState<Status>('completed');
  const [errorMessage, setErrorMessage] = useState('');

  const getLatestBatch = useCallback(() => {
    OrganizationUpgradesService.latest().then((latestBatch) => {
      if (latestBatch) {
        setLatestBatch(latestBatch);
        setStatus(latestBatch.status as Status);
      }
    });
  }, []);

  useEffect(getLatestBatch, [getLatestBatch]);

  const handleLabelClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessages([]);
    const file = event.target.files?.[0];
    if (!file) {
      return;
    }

    setButtonDisabled(true);
    convertAndValidateCSV(file)
      .then(async (batch) => {
        try {
          const newBatch = await OrganizationUpgradesService.createOrganizationUpgrade({ organizationUpgrades: batch });
          setLatestBatch(newBatch);
          setStatus('scheduled');
        } catch (error) {
          setErrorMessages(['An error has occurred, please try again']);
          getLatestBatch();
        }
      })
      .catch(setErrorMessages)
      .finally(() => {
        setButtonDisabled(false);
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
      });
  };

  const onDownloadLogs = ({ from, to }: typeof initialFormValues) => {
    setErrorMessage('');
    OrganizationUpgradesService.export({
      startDate: String(new Date(from)),
      endDate: String(new Date(to)),
    })
      .then(({ csvData, fileName }) => {
        const csvContent = `data:text/csv;charset=utf-8,${csvData}`;
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', fileName);
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
      })
      .catch(() => setErrorMessage('No logs could be found for this period. Try again with a different range'));
  };

  const cancelBatch = () => {
    setErrorMessages([]);
    OrganizationUpgradesService.cancelLatest()
      .then(() => setStatus('canceled'))
      .catch(() => setErrorMessage("Sorry, we couldn't cancel this batch. Try again"));
  };

  return (
    <div className="u-padLg sui-w-full" id="bulk-update-page">
      <div className="u-spaceBottomLg">
        <Breadcrumbs
          breadcrumbs={[
            <Link to="/">All Tools</Link>,
            <Link className="u-colorNeutral8" to="/bulk-upgrade">
              Bulk upgrade tool
            </Link>,
          ]}
        />
      </div>

      <div className="sui-flex sui-flex-col sui-items-center">
        <ErrorMessage className={errorMessage ? '' : 'sui-hidden'} message={errorMessage} />

        <section className="container">
          <div>
            <h2 className="sui-text-desktop-6">Upload a csv of UUIDs to upgrade</h2>
            <p className="sui-text-desktop-2 sui-mt-1">
              Batches are run at {scheduledBatchRunTime}.{' '}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.google.com/spreadsheets/d/1ZKuXRJVdCMvV2HolM8yrT8tivWCNeb5a54dQmUFSEBQ"
              >
                Download a sample csv template
              </a>
            </p>
          </div>
          <input type="file" accept=".csv" style={{ display: 'none' }} onChange={handleFileChange} ref={fileInputRef} />
          {(status === 'canceled' || status === 'failed' || status === 'completed') && (
            <LabelButton disabled={buttonDisabled} onClick={handleLabelClick}>
              + Upload CSV
            </LabelButton>
          )}
          <div className="error-messages">
            {errorMessages.map((message, index) => (
              <div key={index} className="error-message" data-testid="error-message">
                {message}
              </div>
            ))}
          </div>

          {status === 'suspended' && (
            <p className="sui-text-desktop-2">
              Last batch was suspended. Upload not available until the suspended batch is resolved. You can post
              questions or request help in the #sportsorg-bulkupgrades slack channel.
            </p>
          )}

          {(status === 'scheduled' || status === 'processing') && (
            <>
              <div className="sui-flex sui-flex-col sui-items-center">
                <LabelButton
                  disabled
                  icon="refresh"
                  sentiment="success"
                  variantType="secondary"
                  labelText={`Batch ${status === 'scheduled' ? 'scheduled' : 'processing'}`}
                />
                <p className="sui-text-desktop-2 sui-text-center sui-mt-1">
                  Current batch was uploaded{' '}
                  {new Date(latestBatch?.uploadedAt || '').toLocaleString('en-US', { timeZoneName: 'short' })} and
                  contains {latestBatch?.numberOfUuids} UUIDs
                </p>
              </div>

              {status === 'scheduled' && (
                <LabelButton
                  icon="close"
                  variantType="tertiary"
                  sentiment="negative"
                  labelText="Cancel batch"
                  onClick={cancelBatch}
                />
              )}
            </>
          )}
        </section>

        <section className="container sui-mt-3">
          <h2 className="sui-text-desktop-5">Download an upgrade log csv</h2>
          <p className="sui-w-full sui-font-semibold">When was it run?</p>
          <Formik initialValues={initialFormValues} onSubmit={onDownloadLogs} validationSchema={validationSchema}>
            <Form id="bulk-update-form">
              <div className="sui-flex">
                <FormField
                  required
                  mods="sui-mt-0"
                  label="From"
                  name="from"
                  placeholder="mm/dd/yyyy"
                  type={FormFieldType.DATETIME}
                />
                <FormField
                  required
                  mods="sui-ml-2"
                  label="To"
                  name="to"
                  placeholder="mm/dd/yyyy"
                  type={FormFieldType.DATETIME}
                />
              </div>
              <div className="sui-flex sui-justify-center">
                <LabelButton
                  type="submit"
                  className="sui-mt-2"
                  icon="download"
                  labelText="Download log"
                  onClick={() => {}}
                />
              </div>
            </Form>
          </Formik>
        </section>
      </div>
    </div>
  );
};

export default BulkUpdatePage;
