import { Button, Loader, Modal } from '@teamsnap/teamsnap-ui';
import { OrganizationsService, ProgramType, RoleType, RolesService, SeasonsService } from 'core/api';
import useRestRequest from 'core/api/useRestRequest';
import RolesAndPermissions, {
  defaultRole,
  Role,
} from 'frontend-toolkit/dist/components/RolesAndPermissions/RolesPermissions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'state/hooks';
import { loadProgramsByOrgId, useProgramsStateSelector } from 'state/programs/programSlice';

export interface AddRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationId: number;
  organizationName: string;
  currentRoles?: RoleType[];
}

export interface FormDataType {
  type: string;
  scopeIds?: number[];
  roleIds?: number[];
}

export const ROLE_TYPES = [
  { label: 'Organization Admin', value: 'organization_admin' },
  { label: 'Program Admin', value: 'program_admin' },
  { label: 'Program Coordinator', value: 'program_coordinator' },
];

export interface AddRoleModalBodyProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (f: FormDataType[], d: number[]) => void;
  organizationId: number;
  organizationName: string;
  currentRoles?: RoleType[];
  programs: ProgramType[];
}

export interface AddSupportRoleModalProps {
  isOpen: boolean;
  onClose: () => void;
  organizationId: number;
  organizationName: string;
}

const getDivisions = ({ variables: { seasonId } }: { variables: { seasonId: number } }) =>
  SeasonsService.list(seasonId).then((x) => ({
    data: {
      showSeason: {
        id: seasonId,
        divisions: x.map((y) => ({ id: y.id, name: y.name })),
      },
    },
  }));

export const AddSupportRoleModal = ({
  isOpen,
  onClose,
  organizationId,
  organizationName,
}: AddSupportRoleModalProps) => {
  const { data: programs, processing: programsLoading } = useProgramsStateSelector();
  const [divisionsLoaded, setDivisionsLoaded] = useState(false);
  const [formData, setFormData] = useState([defaultRole]);

  const [getSeason, getRoles] = useMemo(
    () => [OrganizationsService.getSeasons(organizationId), RolesService.listSupportRoles(organizationId)],
    [organizationId]
  );

  const { data: rolesData, loading: rolesLoading } = useRestRequest(getRoles);
  const { data: seasonsData } = useRestRequest(getSeason);

  const onMountRolesAndPermissions = useCallback(
    async (rolesAndPermissions) => {
      if (divisionsLoaded || !rolesAndPermissions || !rolesData) {
        return;
      }

      setDivisionsLoaded(true);

      if (!rolesData.length) return;

      await rolesAndPermissions?.loadDivisions(
        ...rolesData.filter((x) => x.type === Role.DivisionCoordinator).map((role) => `${role.season?.id || ''}`)
      );

      setFormData(
        rolesData.map((x) => ({
          role: x.type,
          roleError: false,
          season: `${x.season?.id || ''}`,
          seasonError: false,
          programs: (x.programs || []).map((y) => `${y.id}`),
          programsError: false,
          divisions: (x.divisions || []).map((y) => `${y.id}`),
          divisionsError: false,
        }))
      );
    },
    [divisionsLoaded, rolesData]
  );

  const seasons = (seasonsData || []).map((x) => ({ value: `${x.id}`, label: x.fullName }));

  const dispatch = useAppDispatch();

  const currentRoles = rolesData?.filter((role) => role.organizationId === organizationId);

  useEffect(() => {
    if (organizationId) {
      dispatch(loadProgramsByOrgId(organizationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (!organizationId) return;

    const form = formData.map((x) => ({
      ...x,
      roleError: !x.role,
      programsError: [Role.ProgramAdmin, Role.ProgramCoordinator].includes(x.role as Role) && !x.programs.length,
      seasonError: x.role === Role.DivisionCoordinator && !x.season,
      divisionsError: x.role === Role.DivisionCoordinator && !x.divisions.length,
    }));

    setFormData(form);
    if (form.some((x) => x.roleError || x.programsError || x.seasonError || x.divisionsError)) {
      return;
    }

    RolesService.updateSupportRoles({
      organizationId,
      supportRoles: formData.map((x) => ({
        type: x.role,
        programIds: x.programs.map((y) => +y),
        divisionIds: x.divisions.map((y) => +y),
      })),
    }).then(() => {
      onClose();
    });
  };

  if (programsLoading || rolesLoading || !programs)
    return (
      <Modal show closeFn={() => {}} heading={''}>
        <Loader type="spin" text="Loading" />
      </Modal>
    );

  return (
    <Modal
      heading={`${currentRoles?.length ? 'Manage My Roles in ' : 'Add Myself to'} ${organizationName}`}
      show={isOpen}
      closeFn={onClose}
    >
      <RolesAndPermissions
        isLoadingDivisions={!divisionsLoaded}
        ref={onMountRolesAndPermissions}
        programs={programs.map((x) => ({ value: `${x.id}`, label: x.name }))}
        roles={formData}
        setRoles={setFormData}
        seasons={seasons}
        queryDivisions={getDivisions}
      />

      <div className="Modal-footer u-flex u-flexJustifyEnd">
        <Button color="default" onClick={onClose} mods="u-spaceRightSm">
          Cancel
        </Button>
        <Button color="primary" onClick={onSubmit} mods="u-spaceRightSm">
          {currentRoles?.length ? 'Save' : 'Add Me'}
        </Button>
      </div>
    </Modal>
  );
};
