import { useState } from 'react';
import { CancelablePromise } from '../../core/api';

type ApiResponse = {
  state: 'loading' | 'success' | 'uninitialized' | 'error';
};

export const useContentApiCall = (apiCall: () => CancelablePromise<unknown>) => {
  const [result, setResult] = useState<ApiResponse>({ state: 'uninitialized' });

  const trigger = () => {
    setResult({ state: 'loading' });
    apiCall()
      .then(() => setResult({ state: 'success' }))
      .catch(() => setResult({ state: 'error' }));
  };

  return [trigger, result] as const;
};

export type ResultState = ApiResponse['state'];
