import { StrictMode } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

import { OpenAPI } from 'core/api';
import { API_URL } from 'core/constants';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

import { Root } from 'pages/root';

import { store } from './state/store';

import * as Sentry from '@sentry/react';
import { SENTRY_URL } from 'core/constants';
import '@teamsnap/snap-ui/build/snap-ui.min.css';
import '@teamsnap/teamsnap-ui/src/css/teamsnap-ui.scss';
import ENVIRONMENT from 'core/environment';

OpenAPI.BASE = API_URL;
OpenAPI.WITH_CREDENTIALS = true;

const buildId = process.env.BUILD_ID || '';

if (ENVIRONMENT as string !== 'local') {
  Sentry.init({
    dsn: SENTRY_URL,
    environment: ENVIRONMENT,
    release: `admin-frontend@${buildId}`,
    // allowUrls is for the staging and production environments and will help filter noise not related to the application.
    // The regex below will match the staging and production urls.
    allowUrls: [/^https:\/\/(?:staging\.)?admin\.teamsnap\.com/],
    ignoreErrors: [
      // We are ignoring ResizeObserver loop limit exceeded errors because they are not actionable and are not causing any issues.
      // They stem from use of a 3rd-party widget.
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
  });
}

render(
  <StrictMode>
    <Provider store={store}>
      <Sentry.ErrorBoundary
          beforeCapture={(scope) => {
            scope.setTag('error_boundary', 'index');
          }}
          fallback={<ErrorMessage message="Oops, something went wrong!" />}
        >
          <Root />
      </Sentry.ErrorBoundary> 
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
