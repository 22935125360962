/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationUpgradeBatch } from '../models/OrganizationUpgradeBatch';
import type { OrganizationUpgradeCreate } from '../models/OrganizationUpgradeCreate';
import type { OrganizationUpgradeExport } from '../models/OrganizationUpgradeExport';
import type { OrganizationUpgradeExportResponseData } from '../models/OrganizationUpgradeExportResponseData';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class OrganizationUpgradesService {

    /**
     * @param requestBody
     * @returns OrganizationUpgradeBatch OK
     * @throws ApiError
     */
    public static createOrganizationUpgrade(
        requestBody: OrganizationUpgradeCreate,
    ): CancelablePromise<OrganizationUpgradeBatch> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization_upgrades',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static latest(): CancelablePromise<OrganizationUpgradeBatch | null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/organization_upgrades/latest',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static cancelLatest(): CancelablePromise<OrganizationUpgradeBatch | null> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organization_upgrades/cancel_latest',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @param requestBody
     * @returns OrganizationUpgradeExportResponseData OK
     * @throws ApiError
     */
    public static export(
        requestBody: OrganizationUpgradeExport,
    ): CancelablePromise<OrganizationUpgradeExportResponseData> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization_upgrades/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static suspendProcessing(): CancelablePromise<OrganizationUpgradeBatch | null> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/organization_upgrades/suspend_processing',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

    /**
     * @returns any OK
     * @throws ApiError
     */
    public static restartSuspended(): CancelablePromise<OrganizationUpgradeBatch | null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/organization_upgrades/restart_suspended',
            errors: {
                403: `Unauthorized`,
            },
        });
    }

}
