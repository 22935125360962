/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetBillingTransactionForStripeResponse } from '../models/GetBillingTransactionForStripeResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BillingTransactionsService {

    /**
     * @param invoiceNumber
     * @returns GetBillingTransactionForStripeResponse OK
     * @throws ApiError
     */
    public static get(
        invoiceNumber: string,
    ): CancelablePromise<GetBillingTransactionForStripeResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing_transactions/stripe/{invoice_number}',
            path: {
                'invoice_number': invoiceNumber,
            },
        });
    }

}
